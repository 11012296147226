<script>
import { HttpError } from "@/components/Error";
import UpdateModel from "./UpdateModel";

export default {
  mixins: [UpdateModel],
  computed: {
    _showCostPrice() {
      return this.cfopApplications.includes("incoming");
    },
  },
  methods: {
    _unitPriceOf(product) {
      if (this._showCostPrice) {
        return product.costPrice;
      }
      return product.salePrice;
    },
    async _getProductFiscalData(productID) {
      let res = {
        cfop: undefined,
        icmsTaxCode: undefined,
        ipiTaxCode: undefined,
        pisTaxCode: undefined,
        cofinsTaxCode: undefined,
      };
      if (!productID) {
        return res;
      }

      try {
        const { data } = await this.$http.get(
          `/v1/products/${productID}/fiscal?application=${this.nfeOperation}&interstate=${this.interstate}`
        );
        res.cfop = data.cfop;
        res.icmsTaxCode = data.icms_tax_code;
        res.pisTaxCode = data.pis_tax_code;
        res.cofinsTaxCode = data.cofins_tax_code;
        res.ipiTaxCode = data.ipi_tax_code;
      } catch (err) {
        if (err instanceof HttpError) {
          if (err.status !== 404) {
            this.$sentry.captureException(err);
          }
        } else {
          console.error(err);
          this.$sentry.captureException(err);
        }
      }

      return res;
    },
    async _productChanged(current, product) {
      let unitPrice = this._unitPriceOf(product || {});

      const {
        cfop,
        icmsTaxCode,
        pisTaxCode,
        cofinsTaxCode,
        ipiTaxCode,
      } = await this._getProductFiscalData(product?.id);

      return this.updateObject(current, {
        product,
        unit: product?.unit || "",
        unitPrice: unitPrice,
        ncm: product?.ncm || "",
        cest: product?.cest,
        goodsOrigin: parseInt(product?.origin || 0),
        cfop,
        icmsTaxCode,
        pisTaxCode,
        cofinsTaxCode,
        ipiTaxCode,
      });
    },
  },
};
</script>
