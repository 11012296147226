<template>
  <v-dialog
    scrollable
    :fullscreen="$vuetify.breakpoint.xsOnly"
    :width="computedWidth"
    :value="value"
    @input="close"
  >
    <v-card :loading="loading">
      <v-card-title class="text-button">
        Cancelamento da Nota Fiscal {{ serie }} / {{ number }}
      </v-card-title>

      <v-divider />

      <v-card-text>
        <v-container>
          <v-form ref="form">
            <v-row>
              <v-col cols="12">
                <v-textarea
                  outlined
                  auto-grow
                  hide-details="auto"
                  counter="255"
                  label="Motivo do cancelamento"
                  rows="3"
                  :rules="[minLengthRule(15), maxLengthRule(255)]"
                  v-model="reason"
                />
              </v-col>
            </v-row>
          </v-form>
        </v-container>
      </v-card-text>

      <v-card-actions class="pb-6 justify-center">
        <v-btn depressed :disabled="loading" @click="close">
          Fechar
        </v-btn>

        <v-btn large color="error" :loading="loading" @click="cancel">
          <v-icon left>mdi-cancel</v-icon>
          Cancelar
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import Rules from "@/mixins/Rules";
import { HttpError } from "@/components/Error";

export default {
  name: "NotaFiscalCancel",
  mixins: [Rules],
  props: {
    value: Boolean,
    nfId: String,
    serie: [Number, String],
    number: [Number, String],
  },
  data: () => ({
    loading: false,
    reason: "",
  }),
  computed: {
    computedWidth() {
      switch (this.$vuetify.breakpoint.name) {
        case "sm":
          return "80%";
        case "md":
          return "60%";
        case "lg":
          return "45%";
        case "xl":
          return "30%";
      }
      return "auto";
    },
  },
  methods: {
    close() {
      this.$emit("input", false);
    },
    async cancel() {
      this.loading = true;
      if (!this.$refs.form.validate()) {
        this.loading = false;
        return;
      }

      try {
        const { data } = await this.$http.post(
          `/v1/nfe/documents/${this.nfId}/cancel`,
          {
            reason: this.reason,
          }
        );

        const {
          cancelation_protocol: protocol,
          cancelation_reason: reason,
          canceled_at,
        } = data.data;

        this.$emit("update:canceled", {
          protocol,
          reason,
          canceled_at,
        });

        this.notifySuccess("Nota Fiscal cancelada com sucesso 😅");
        this.$nextTick().then(() => {
          this.close();
        });
      } catch (err) {
        if (err instanceof HttpError) {
          switch (err.code) {
            case "deadline_to_cancel_nfe_exceeded":
              this.notifyError(err.msg);
              break;
            default:
              this.$sentry.captureException(err);
              this.notifyError("Ocorreu um erro ao cancelar a Nota Fiscal 😢");
          }
        } else {
          console.error(err);
          this.$sentry.captureException(err);
        }
      } finally {
        this.loading = false;
      }
    },
  },
};
</script>
