<template>
  <div>
    <v-card-title class="section-title">
      Dados Fiscais dos Produtos
    </v-card-title>

    <v-card-text>
      <v-row>
        <v-col cols="12">
          <v-data-table
            disable-filtering
            disable-pagination
            disable-sort
            fixed-header
            hide-default-footer
            :dense="$vuetify.breakpoint.mdAndDown"
            :headers="headers"
            :items="items"
          >
            <template v-slot:item.remove="{ index }" v-if="!readonly">
              <v-btn icon small color="grey" @click="removeLine(index)">
                <v-icon small>mdi-trash-can-outline</v-icon>
              </v-btn>
            </template>

            <template v-slot:item.product="{ item }">
              <span v-if="readonly">{{ item.product.description }}</span>
              <product-field
                v-else
                required
                solo
                flat
                hide-details
                showPrice
                showStock
                label=""
                placeholder="Nome do produto"
                :showCostPrice="_showCostPrice"
                :value="item.product"
                @input="productChanged(item, $event)"
              />
            </template>

            <template v-slot:item.quantity="{ item }">
              <span v-if="readonly">
                <number :value="item.quantity" />
                {{ item.unit }}
              </span>

              <number-field
                v-else
                required
                solo
                flat
                hide-details
                placeholder="0,0000"
                class="v-text-field--text-right"
                :suffix="item.unit"
                :precision="{
                  min: 0,
                  max: 4,
                }"
                :value="item.quantity"
                @change="updateTotalPrice(item, 'quantity', $event)"
              />
            </template>

            <template v-slot:item.unitPrice="{ item }">
              <div v-if="readonly || priceReadonly" class="text-right">
                <currency :value="item.unitPrice || 0" />
              </div>

              <number-field
                v-else
                required
                solo
                flat
                hide-details
                placeholder="0,00"
                class="v-text-field--text-right"
                prefix="R$"
                :precision="{
                  min: 2,
                  max: 4,
                }"
                :value="item.unitPrice"
                @change="updateTotalPrice(item, 'unitPrice', $event)"
              />
            </template>

            <template v-slot:item.totalPrice="{ value }">
              <currency :value="value || 0" />
            </template>

            <template v-slot:item.ncm="{ item }">
              <ncm-field text :value="item.ncm" />
            </template>

            <template v-slot:item.cfop="{ item }">
              <cfop-field text :value="item.cfop" />
            </template>

            <template v-slot:item.aliquot="{ item }">
              <template v-if="lineIsValid(item)">
                <template v-if="lineIsFiscalValid(item)">
                  <v-btn depressed small block @click="editItem = item">
                    <v-icon left color="success">mdi-check-circle</v-icon>
                    <span v-if="readonly">Visualizar</span>
                    <span v-else>Editar</span>
                  </v-btn>
                </template>

                <template v-else>
                  <v-tooltip bottom max-width="155">
                    <template v-slot:activator="{ on, attrs }">
                      <v-btn
                        depressed
                        small
                        block
                        :color="!item.fiscalValid ? 'error' : undefined"
                        v-bind="attrs"
                        v-on="on"
                        @click="editItem = item"
                      >
                        <v-icon left color="warning">mdi-alert-octagon</v-icon>
                        Corrigir
                      </v-btn>
                    </template>

                    <span class="d-block text-center">
                      Informações fiscais incompletas
                    </span>
                  </v-tooltip>
                </template>
              </template>
            </template>

            <template v-if="!readonly" v-slot:body.append="{ isMobile }">
              <template v-if="isMobile">
                <v-col>
                  <v-btn small text color="success" @click="newLine">
                    <v-icon left>mdi-plus-thick</v-icon>
                    Adicionar mais um produto
                  </v-btn>
                </v-col>
              </template>

              <tr v-else>
                <td :colspan="readonly ? 3 : 4">
                  <v-btn small text color="success" @click="newLine">
                    <v-icon left>mdi-plus-thick</v-icon>
                    Adicionar mais um produto
                  </v-btn>
                </td>

                <td class="text-right">
                  <currency :value="total || 0" />
                </td>
                <td></td>
              </tr>
            </template>
          </v-data-table>
        </v-col>
      </v-row>
    </v-card-text>

    <product-edit
      :readonly="readonly"
      :value="editItem"
      :fiscal-regime="fiscalRegime"
      :nfe-operation="nfeOperation"
      :interstate="interstate"
      :cfop-applications="cfopApplications"
      @input="editItemUpdated"
      @close="editItem = null"
    />
  </div>
</template>

<script>
import Decimal from "decimal.js";
import UpdateModel from "@/mixins/UpdateModel";
import ProductChanged from "@/mixins/ProductChanged";
import Number from "@/components/Number";
import Currency from "@/components/Currency";
import NumberField from "@/components/NumberField";
import ProductField from "@/components/ProductField";
import CfopField from "@/components/CfopField";
import NcmField from "@/components/NcmField";
import ProductEdit from "./ProductEdit";

export default {
  name: "NotaFiscalProducts",
  mixins: [UpdateModel, ProductChanged],
  components: {
    Number,
    Currency,
    NumberField,
    ProductField,
    CfopField,
    NcmField,
    ProductEdit,
  },
  props: {
    readonly: Boolean,
    priceReadonly: Boolean,
    value: Array,
    fiscalRegime: {
      required: true,
      type: String,
    },
    nfeOperation: {
      required: true,
      type: String,
    },
    interstate: Boolean,
    cfopApplications: {
      type: Array,
      default: () => [],
    },
    total: Number,
  },
  data: () => ({
    editItem: null,
  }),
  computed: {
    local() {
      return this.value || [];
    },
    headers() {
      let headers = [];
      if (!this.readonly) {
        headers.push({
          value: "remove",
          align: "center",
          width: 32,
        });
      }
      return headers.concat([
        {
          text: "Produto",
          value: "product",
        },
        {
          text: "Quant.",
          value: "quantity",
          align: this.readonly ? "right" : "center",
          width: 118,
        },
        {
          text: "Valor Unit.",
          value: "unitPrice",
          align: this.readonly || this.priceReadonly ? "right" : "center",
          width: 126,
        },
        {
          text: "Total",
          value: "totalPrice",
          align: "right",
          width: 94,
        },
        {
          text: "NCM",
          value: "ncm",
          align: "center",
          width: 80,
        },
        {
          text: "CFOP",
          value: "cfop",
          align: "center",
          width: 80,
        },
        {
          text: "Info. Fiscais",
          value: "aliquot",
          width: 140,
        },
      ]);
    },
    items() {
      return this.local.map((item, i) => {
        return this.updateObject(item, { index: i });
      });
    },
    calculatedTotal() {
      const total = this.items.reduce((total, product) => {
        return total.add(product.totalPrice || 0);
      }, new Decimal(0));
      return total.toDecimalPlaces(2);
    },
  },
  watch: {
    calculatedTotal(v) {
      const total = v ? v.toNumber() : v;
      this.$emit("update:total", total);
    },
    nfeOperation() {
      if (!this.readonly) {
        this.updateCfopAndTax();
      }
    },
    interstate() {
      if (!this.readonly) {
        this.updateCfopAndTax();
      }
    },
  },
  methods: {
    newLine() {
      this.addValueItem({
        unit: "UN",
        goodsOrigin: 0,
        fiscalValid: true,
      });
    },
    removeLine(index) {
      this.deleteValueIndex(index);
      this.$nextTick().then(() => {
        if (this.local.length === 0) {
          this.newLine();
        }
      });
    },
    async productChanged(item, product) {
      const v = await this._productChanged(item, product);
      if (!v) {
        return;
      }

      const unitPriceDec = new Decimal(v.unitPrice || 0);
      const quantDec = new Decimal(v.quantity || 0);
      v.totalPrice = unitPriceDec.mul(quantDec);
      this.updateValueIndex(item.index, v);
    },
    updateTotalPrice(current, field, v) {
      let unitPriceDec, quantDec;
      switch (field) {
        case "quantity":
          quantDec = new Decimal(v || 0);
          unitPriceDec = new Decimal(current.unitPrice || 0);
          break;
        case "unitPrice":
          unitPriceDec = new Decimal(v || 0);
          quantDec = new Decimal(current.quantity || 0);
          break;
      }

      const totalPrice = unitPriceDec.mul(quantDec).toDecimalPlaces(2);
      const item = this.updateObject(current, { [field]: v, totalPrice });
      this.updateValueIndex(item.index, item);
    },
    lineIsValid(item) {
      const description = item.product?.description;
      const quantDec = new Decimal(item.quantity || 0);
      const unitPriceDec = new Decimal(item.unitPrice || 0);
      return !!description && !quantDec.equals(0) && !unitPriceDec.equals(0);
    },
    lineIsFiscalValid(item) {
      return (
        item.product?.id &&
        item.ncm &&
        item.cfop &&
        item.unit &&
        item.icmsTaxCode &&
        item.pisTaxCode &&
        item.cofinsTaxCode
      );
    },
    editItemUpdated(item) {
      this.updateValueIndex(item.index, item);
    },
    validate() {
      if (this.local.length === 0) {
        this.newLine();
        return ["Nenhum produto foi adicionado"];
      }

      let errs = [];
      for (const index in this.value) {
        const line = this.value[index];
        const descOrIndex = line.product?.description || parseInt(index) + 1;
        if (!this.lineIsValid(line)) {
          errs.push(
            `Produto <strong>${descOrIndex}</strong> não está completo`
          );
        } else if (!this.lineIsFiscalValid(line)) {
          const v = this.updateObjectKey(line, "fiscalValid", false);
          this.updateValueIndex(index, v);
          errs.push(
            `Produto <strong>${descOrIndex}</strong> faltando informações fiscais`
          );
        }
      }
      return errs.length > 0 ? errs : true;
    },
    async updateCfopAndTax() {
      for (const index in this.items) {
        const line = this.items[index];
        if (!line.product) continue;

        const {
          cfop,
          icmsTaxCode,
          pisTaxCode,
          cofinsTaxCode,
          ipiTaxCode,
        } = await this._getProductFiscalData(line.product.id);

        let updates = {};
        if (cfop != line.cfop) updates.cfop = cfop;
        if (icmsTaxCode != line.icmsTaxCode) updates.icmsTaxCode = icmsTaxCode;
        if (pisTaxCode != line.pisTaxCode) updates.pisTaxCode = pisTaxCode;
        if (cofinsTaxCode != line.cofinsTaxCode)
          updates.cofinsTaxCode = cofinsTaxCode;
        if (ipiTaxCode != line.ipiTaxCode) updates.ipiTaxCode = ipiTaxCode;
        const v = this.updateObject(line, updates);
        this.updateValueIndex(index, v);
      }
    },
  },
  created() {
    if (this.local.length === 0) {
      this.newLine();
    }
  },
};
</script>

<style lang="sass" scoped>
.v-text-field--text-right::v-deep
  input
    text-align: right

.v-data-table::v-deep
    tbody > tr > td, thead > tr > th
      padding-right: 0 !important
      padding-left: 10px !important

      &:last-child
        padding-right: 10px !important

    tbody >
      tr > td
        .v-btn.v-btn--icon, .v-text-field.v-text-field--solo
          margin-left: -6px !important

      tr:hover
        &:not(.v-data-table__expanded__content):not(.v-data-table__empty-wrapper)
          td:first-child
            .v-btn.v-btn--icon
              color: var(--v-error-base) !important
              caret-color: var(--v-error-base) !important

        &:last-child
          background-color: #FFFFFF !important
</style>
