var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('v-card-title',{staticClass:"section-title"},[_vm._v(" Dados Fiscais dos Produtos ")]),_c('v-card-text',[_c('v-row',[_c('v-col',{attrs:{"cols":"12"}},[_c('v-data-table',{attrs:{"disable-filtering":"","disable-pagination":"","disable-sort":"","fixed-header":"","hide-default-footer":"","dense":_vm.$vuetify.breakpoint.mdAndDown,"headers":_vm.headers,"items":_vm.items},scopedSlots:_vm._u([(!_vm.readonly)?{key:"item.remove",fn:function(ref){
var index = ref.index;
return [_c('v-btn',{attrs:{"icon":"","small":"","color":"grey"},on:{"click":function($event){return _vm.removeLine(index)}}},[_c('v-icon',{attrs:{"small":""}},[_vm._v("mdi-trash-can-outline")])],1)]}}:null,{key:"item.product",fn:function(ref){
var item = ref.item;
return [(_vm.readonly)?_c('span',[_vm._v(_vm._s(item.product.description))]):_c('product-field',{attrs:{"required":"","solo":"","flat":"","hide-details":"","showPrice":"","showStock":"","label":"","placeholder":"Nome do produto","showCostPrice":_vm._showCostPrice,"value":item.product},on:{"input":function($event){return _vm.productChanged(item, $event)}}})]}},{key:"item.quantity",fn:function(ref){
var item = ref.item;
return [(_vm.readonly)?_c('span',[_c('number',{attrs:{"value":item.quantity}}),_vm._v(" "+_vm._s(item.unit)+" ")],1):_c('number-field',{staticClass:"v-text-field--text-right",attrs:{"required":"","solo":"","flat":"","hide-details":"","placeholder":"0,0000","suffix":item.unit,"precision":{
                min: 0,
                max: 4,
              },"value":item.quantity},on:{"change":function($event){return _vm.updateTotalPrice(item, 'quantity', $event)}}})]}},{key:"item.unitPrice",fn:function(ref){
              var item = ref.item;
return [(_vm.readonly || _vm.priceReadonly)?_c('div',{staticClass:"text-right"},[_c('currency',{attrs:{"value":item.unitPrice || 0}})],1):_c('number-field',{staticClass:"v-text-field--text-right",attrs:{"required":"","solo":"","flat":"","hide-details":"","placeholder":"0,00","prefix":"R$","precision":{
                min: 2,
                max: 4,
              },"value":item.unitPrice},on:{"change":function($event){return _vm.updateTotalPrice(item, 'unitPrice', $event)}}})]}},{key:"item.totalPrice",fn:function(ref){
              var value = ref.value;
return [_c('currency',{attrs:{"value":value || 0}})]}},{key:"item.ncm",fn:function(ref){
              var item = ref.item;
return [_c('ncm-field',{attrs:{"text":"","value":item.ncm}})]}},{key:"item.cfop",fn:function(ref){
              var item = ref.item;
return [_c('cfop-field',{attrs:{"text":"","value":item.cfop}})]}},{key:"item.aliquot",fn:function(ref){
              var item = ref.item;
return [(_vm.lineIsValid(item))?[(_vm.lineIsFiscalValid(item))?[_c('v-btn',{attrs:{"depressed":"","small":"","block":""},on:{"click":function($event){_vm.editItem = item}}},[_c('v-icon',{attrs:{"left":"","color":"success"}},[_vm._v("mdi-check-circle")]),(_vm.readonly)?_c('span',[_vm._v("Visualizar")]):_c('span',[_vm._v("Editar")])],1)]:[_c('v-tooltip',{attrs:{"bottom":"","max-width":"155"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
              var on = ref.on;
              var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"depressed":"","small":"","block":"","color":!item.fiscalValid ? 'error' : undefined},on:{"click":function($event){_vm.editItem = item}}},'v-btn',attrs,false),on),[_c('v-icon',{attrs:{"left":"","color":"warning"}},[_vm._v("mdi-alert-octagon")]),_vm._v(" Corrigir ")],1)]}}],null,true)},[_c('span',{staticClass:"d-block text-center"},[_vm._v(" Informações fiscais incompletas ")])])]]:_vm._e()]}},(!_vm.readonly)?{key:"body.append",fn:function(ref){
              var isMobile = ref.isMobile;
return [(isMobile)?[_c('v-col',[_c('v-btn',{attrs:{"small":"","text":"","color":"success"},on:{"click":_vm.newLine}},[_c('v-icon',{attrs:{"left":""}},[_vm._v("mdi-plus-thick")]),_vm._v(" Adicionar mais um produto ")],1)],1)]:_c('tr',[_c('td',{attrs:{"colspan":_vm.readonly ? 3 : 4}},[_c('v-btn',{attrs:{"small":"","text":"","color":"success"},on:{"click":_vm.newLine}},[_c('v-icon',{attrs:{"left":""}},[_vm._v("mdi-plus-thick")]),_vm._v(" Adicionar mais um produto ")],1)],1),_c('td',{staticClass:"text-right"},[_c('currency',{attrs:{"value":_vm.total || 0}})],1),_c('td')])]}}:null],null,true)})],1)],1)],1),_c('product-edit',{attrs:{"readonly":_vm.readonly,"value":_vm.editItem,"fiscal-regime":_vm.fiscalRegime,"nfe-operation":_vm.nfeOperation,"interstate":_vm.interstate,"cfop-applications":_vm.cfopApplications},on:{"input":_vm.editItemUpdated,"close":function($event){_vm.editItem = null}}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }