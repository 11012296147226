<script>
import { mapState } from "vuex";
import Decimal from "decimal.js";
import { HttpError } from "@/components/Error";

export default {
  name: "Nfe",
  data: () => ({
    totalLines: null,
    totalDiscount: null,
  }),
  computed: {
    ...mapState("auth", ["company"]),
    authorizedAt() {
      if (this.value.authorized) {
        return new Date(this.value.authorized.authorized_at);
      }
      return null;
    },
    issueDate() {
      if (this.value.issue_date) {
        return new Date(this.value.issue_date);
      }
      return null;
    },
    oldNfeFormat() {
      let nfe = this.nfe;
      nfe.invoice.receivables = nfe.invoice.receivables.map((payment) => ({
        ...payment,
        method: this.convertPaymentMethod(payment.method),
      }));
      return nfe;
    },
    nfe() {
      return {
        id: this.value.id,
        model: this.value.model,
        serie: this.value.serie,
        number: this.value.number,
        point_of_sale: this.value.point_of_sale,
        issue_date: this.$moment().format("YYYY-MM-DDTHH:mm:ssZ"),
        issuer: this._issuer,
        reference_documents: this._referenceDocuments,
        purpose: this._purpose,
        operation_type: this._operationType,
        operation_description: this._operationDescription,
        recipient: this._recipient,
        lines: this._lines,
        payment_term: this.payments.length == 1 ? "paid" : "pay_later",
        invoice: this._invoice,
        delivery: this._shipping,
        notes: this.notes.additional,
        fiscal_notes: this.notes.fiscal,
      };
    },
    _issuer() {
      if (!this.company) return null;
      return {
        cnpj: this.company.cnpj,
        name: this.company.fiscalName,
        trade_name: this.company.name,
        state_tax_number: this.company.stateTaxNumber || "",
        address: {
          street: this.company.address.street,
          number: this.company.address.number,
          additional: this.company.address.additional,
          neighbourhood: this.company.address.neighbourhood,
          city_id: this.company.address.city.id,
          state: this.company.address.city.state,
          zipcode: this.company.address.zipcode,
        },
        phone: this.company?.phones?.length > 0 ? this.company.phones[0] : null,
        tax_regime: this.company.fiscalRegime,
      };
    },
    _referenceDocuments() {
      if (!this.referenceDocument) return null;
      return [
        {
          type: "nfe",
          key: this.referenceDocument,
        },
      ];
    },
    _purpose() {
      if (this.operation.type?.value === "devolucao") return "return";
      if (this.operation.type?.value === "estorno") return "adjustment";
      return "normal";
    },
    _operationType() {
      switch (this.operation.type?.value) {
        case "venda":
          return "outgoing";
        case "compra":
          return "incoming";
        case "remessa":
          switch (this.operation.reason?.value) {
            case "transferencia":
              return "outgoing";
          }
      }
      return this.operation.direction?.value || "";
    },
    _operationDescription() {
      const { type, description, reason, direction } = this.operation;
      if (description) return description;

      switch (type?.value) {
        case "venda":
          if (!reason || !reason.value) return type.text;
          if (["entrega_futura", "industrializacao"].includes(reason.value)) {
            return type.text + " para " + reason.text;
          }
          return type.text + " " + reason.text;

        case "devolucao":
          if (!direction) return "";
          return type.text + " de " + direction.text;

        case "estorno":
          return "999 - Estorno de NF-e não cancelada no prazo legal";

        case "compra":
          if (!reason) return "";
          if (reason.value === "uso_e_consumo") {
            return type.text + " para " + reason.text;
          }
          return type.text + " de " + reason.text;

        case "remessa":
          if (!reason) return "";
          if (["outras_saidas", "transferencia"].includes(reason.value)) {
            return reason.text;
          }
          if (
            [
              "amostra_gratis",
              "demonstracao",
              "ativo_imobilizado_uso_fora",
            ].includes(reason.value)
          ) {
            return type.text + " de " + reason.text;
          }
          return type.text + " para " + reason.text;

        case "retorno":
          if (!reason) return "";
          if (reason.value === "outras_entradas") return reason.text;
          return type.text + " de " + reason.text;
      }
      return "";
    },
    _recipient() {
      if (!this.recipient) return null;

      let recipient;

      if (this.recipient.type === "company") {
        recipient = {
          cnpj: this.recipient.taxNumber,
          name: this.recipient.fiscalName,
          state_tax_number: this.recipient.stateTaxNumber || "",
        };
      } else {
        recipient = {
          cpf: this.recipient.taxNumber,
          name: this.recipient.name,
        };
      }

      recipient = Object.assign(recipient, {
        phone: this.recipient.phone,
        email: this.recipient.email,
      });

      if (this.recipient.address && this.recipient.address.city?.id) {
        recipient = Object.assign(recipient, {
          address: {
            street: this.recipient.address.street,
            number: this.recipient.address.number,
            additional: this.recipient.address.additional,
            neighbourhood: this.recipient.address.neighbourhood,
            city_id: this.recipient.address.city?.id,
            state: this.recipient.address.city?.state,
            zipcode: this.recipient.address.zipcode,
          },
        });
      }
      return recipient;
    },
    _lines() {
      return this.products.map((line) => ({
        product_id: line.product?.id,
        description: line.product?.description,
        notes: line.notes,
        barcode: line.barcode,
        ncm: line.ncm,
        cest: line.cest || null,
        cfop: line.cfop,
        unit: line.unit,
        quantity: line.quantity,
        total_price: line.totalPrice,
        goods_origin: line.goodsOrigin,
        icms: {
          tax_code: line.icmsTaxCode,
        },
        pis: {
          tax_code: line.pisTaxCode,
        },
        cofins: {
          tax_code: line.cofinsTaxCode,
        },
        ipi: line.ipiTaxCode
          ? {
              tax_code: line.ipiTaxCode,
            }
          : null,
      }));
    },
    _invoice() {
      return {
        amount: this.grossTotal.toNumber().toString(),
        discount: this.totalDiscount?.toString() || null,
        receivables: this.payments.map((installment) => ({
          amount: installment.amount.toString(),
          due_date: installment.dueDate,
          done: installment.paid,
          method: installment.method || null,
        })),
      };
    },
    _shipping() {
      if (!this.shipping) return null;

      const { type } = this.shipping;
      if (type === "no_shipping") {
        return { type: "collect" };
      }

      if (type === "collect") {
        return {
          type,
          address: {
            street: this.shipping.address.street,
            number: this.shipping.address.number,
            additional: this.shipping.address.additional,
            neighbourhood: this.shipping.address.neighbourhood,
            city_id: this.shipping.address.city?.id,
            state: this.shipping.address.city?.state,
            zipcode: this.shipping.address.zipcode,
          },
        };
      }

      let packages = [
        {
          quantity: this.shipping.packages,
          unit: this.shipping.unit?.value,
          weight: this.shipping.weight,
        },
      ];

      if (type !== "third_party_cif") {
        return { type, packages };
      }

      let vehicle = {
        rntc: this.shipping.courier.vehicle.rntc,
        plate: this.shipping.courier.vehicle.plate,
        state: this.shipping.courier.vehicle.state_code,
      };

      let fiscalData = {};

      if (this.shipping.courier.type === "company") {
        fiscalData.cnpj = this.shipping.courier.taxNumber;
        fiscalData.state_tax_number =
          this.shipping.courier.stateTaxNumber || "";
      } else {
        fiscalData.cpf = this.shipping.courier.taxNumber;
      }

      let company = Object.assign(
        {
          name: this.shipping.courier.name,
          // address
          street: this.shipping.courier.address.street,
          number: this.shipping.courier.address.number,
          neighbourhood: this.shipping.courier.address.neighbourhood,
          city_id: this.shipping.courier.address.city?.id,
          state: this.shipping.courier.address.city?.state,
          zipcode: this.shipping.courier.address.zipcode,
        },
        fiscalData
      );

      return {
        type,
        packages,
        company,
        vehicle,
      };
    },
    // nf totals
    totalShipping() {
      return new Decimal(this.shipping?.cost || 0);
    },
    grossTotal() {
      let total = new Decimal(this.totalLines || 0);
      if (this.shipping?.includeShippingCost) {
        total = total.add(this.totalShipping);
      }
      return total;
    },
    totalNf() {
      let total = this.grossTotal.sub(this.totalDiscount || 0);
      return total.toNumber();
    },
    taxBaseIcms() {
      return new Decimal(0);
    },
    totalIcms() {
      return new Decimal(0);
    },
    taxBaseIcmsSt() {
      return new Decimal(0);
    },
    totalIcmsSt() {
      return new Decimal(0);
    },
    totalIpi() {
      return new Decimal(0);
    },
    totalPis() {
      return new Decimal(0);
    },
    totalCofins() {
      return new Decimal(0);
    },
  },
  methods: {
    // loadNf fill all internal fields with the nfe data
    loadNf(nfe, onlyData) {
      if (onlyData) {
        delete this.value.id;
        delete this.value.serie;
        delete this.value.number;
        delete this.value.issue_date;
        delete this.value.authorized;
        delete this.value.canceled;
        delete this.value.sefaz_id;
        delete this.value.xml_url;
        delete this.value.pdf_url;
      }
      this.referenceDocument = this._loadReferenceDocument(nfe);
      this.operation = this._loadOperation(nfe);
      this.recipient = this._loadRecipient(nfe);
      this.products = this._loadLines(nfe);
      if (nfe.invoice?.amount) {
        this.totalLines = new Decimal(nfe.invoice.amount).toNumber();
      }
      if (nfe.invoice?.discount) {
        this.totalDiscount = new Decimal(nfe.invoice.discount).toNumber();
      }
      this.payments = this._loadPayments(nfe);
      this.shipping = Object.assign(
        {
          address: {},
          courier: {
            vehicle: {},
            address: {},
          },
        },
        this._loadShipping(nfe)
      );
      this.notes = this._loadNotes(nfe);
    },
    _loadReferenceDocument(nfe) {
      if ((nfe.reference_documents || []).length > 0) {
        return nfe.reference_documents[0].key;
      }
      return "";
    },
    _loadOperation(nfe) {
      const description = nfe.operation_description;

      let operationType,
        reason = null,
        direction = null;

      if (/remessa/i.test(description)) {
        operationType = "remessa";
        if (/transfer[eê]ncia/i.test(description)) {
          operationType = "remessa";
          reason = {
            value: "transferencia",
          };
        } else if (/industrializa[cç][aã]o/i.test(description)) {
          reason = {
            value: "industrialização",
          };
          direction = {
            value: nfe.operation_type,
          };
        }
      } else if (/^999 - /i.test(description)) {
        operationType = "estorno";
        direction = {
          value: nfe.operation_type,
        };
      } else if (/devolu[cç][aã]o/i.test(description)) {
        operationType = "devolucao";
        if (/compra/i.test(description)) {
          direction = {
            value: "outgoing",
          };
        } else if (/venda/i.test(description)) {
          direction = {
            value: "incoming",
          };
        }
      } else if (/compra/i.test(description)) {
        operationType = "compra";
      } else {
        operationType = "venda";
      }
      return {
        description,
        type: {
          value: operationType,
        },
        reason,
        direction,
      };
    },
    _loadRecipient(nfe) {
      if (!nfe.recipient) return null;

      let fiscalData;

      if (nfe.recipient.cnpj) {
        fiscalData = {
          type: "company",
          taxNumber: nfe.recipient.cnpj,
          fiscalName: nfe.recipient.name,
          stateTaxNumber: nfe.recipient.state_tax_number,
        };
      } else {
        fiscalData = {
          type: "person",
          taxNumber: nfe.recipient.cpf,
        };
      }

      let address;
      if (nfe.recipient.address) {
        address = {
          street: nfe.recipient.address.street,
          number: nfe.recipient.address.number,
          additional: nfe.recipient.address.additional,
          neighbourhood: nfe.recipient.address.neighbourhood,
          city: {
            id: nfe.recipient.address.city_id,
            name: nfe.recipient.address.city_name,
            state: nfe.recipient.address.state,
          },
          zipcode: nfe.recipient.address.zipcode,
        };
      }

      return Object.assign(
        {
          name: nfe.recipient.name,
          phone: nfe.recipient.phone,
          email: nfe.recipient.email,
          address,
        },
        fiscalData
      );
    },
    _loadLines(nfe) {
      return nfe.lines.map((product) => ({
        product: {
          id: product.product_id,
          description: product.description,
          unit: product.unit,
          ncm: product.ncm,
          cest: product.cest,
          origin: product.goods_origin,
          cost_price: product.unit_price,
          sale_price: product.unit_price,
        },
        notes: product.notes,
        barcode: product.barcode,
        ncm: product.ncm,
        cest: product.cest,
        cfop: product.cfop,
        unit: product.unit,
        quantity: product.quantity,
        unitPrice: product.unit_price,
        totalPrice: product.total_price,
        goodsOrigin: product.goods_origin,
        icmsTaxCode: product.icms.tax_code,
        icmsTaxBaseMode: product.icms.tax_base_mode,
        icmsTaxBase: product.icms.tax_base,
        icmsTaxRate: product.icms.tax_rate,
        icmsTotal: product.icms.total,
        pisTaxCode: product.pis.tax_code,
        pisTaxBase: product.pis.tax_base,
        pisTaxRate: product.pis.tax_rate,
        pisTotal: product.pis.total,
        cofinsTaxCode: product.cofins.tax_code,
        cofinsTaxBase: product.cofins.tax_base,
        cofinsTaxRate: product.cofins.tax_rate,
        cofinsTotal: product.cofins.total,
        ipiTaxCode: product.ipi?.tax_code,
      }));
    },
    _loadPayments(nfe) {
      return nfe.invoice.receivables.map((payment, index) => ({
        id: index + 1,
        amount: payment.amount,
        dueDate: payment.due_date,
        paid: payment.done,
        method: payment.method,
      }));
    },
    _loadShipping(nfe) {
      const { delivery } = nfe;
      const { type } = delivery;

      if (type === "collect") {
        if (!delivery.address) {
          return { type: "no_shipping" };
        }

        return {
          type,
          address: {
            street: delivery.address.street,
            number: delivery.address.number,
            additional: delivery.address.additional,
            neighbourhood: delivery.address.neighbourhood,
            city: {
              id: delivery.address.city_id,
              name: delivery.address.city_name,
              state: delivery.address.state,
            },
            zipcode: delivery.address.zipcode,
          },
        };
      }

      if (delivery.packages?.length > 0) {
        var packages = delivery.packages[0].quantity;
        var unit = {
          value: delivery.packages[0].unit,
        };
        var weight = delivery.packages[0].weight;
      }

      let costDec = nfe.lines.reduce((total, line) => {
        return total.add(line.shipping_cost || 0);
      }, new Decimal(0));
      let cost = costDec.toNumber();

      let includeShippingCost = costDec.greaterThan(0);

      if (type !== "third_party_cif") {
        return {
          type,
          cost,
          includeShippingCost,
          packages,
          unit,
          weight,
        };
      }

      let fiscalData = {};
      if (delivery.company.cnpj) {
        fiscalData.type = "company";
        fiscalData.taxNumber = delivery.company.cnpj;
        fiscalData.stateTaxNumber = delivery.company.state_tax_number;
      } else {
        fiscalData.type = "person";
        fiscalData.taxNumber = delivery.company.cpf;
      }

      let courier = {
        name: delivery.company.name,
        address: {
          street: delivery.company.street,
          number: delivery.company.number,
          neighbourhood: delivery.company.neighbourhood,
          city: {
            id: delivery.company.city_id,
            name: delivery.company.city_name,
            state: delivery.company.state,
          },
          zipcode: delivery.company.zipcode,
        },
        vehicle: {
          rntc: delivery.vehicle.rntc,
          plate: delivery.vehicle.plate,
          state_code: delivery.vehicle.state,
        },
      };
      courier = Object.assign(courier, fiscalData);

      return {
        type,
        courier,
        cost,
        includeShippingCost,
        packages,
        unit,
        weight,
      };
    },
    _loadNotes(nfe) {
      return {
        additional: nfe.notes,
        fiscal: nfe.fiscal_notes,
      };
    },
    async getNf(id, onlyData) {
      let nfe = await this.$store.dispatch("nfes/get", id);
      this.value = nfe;

      let oldNFeFormat = this.convertNFe(nfe);
      Object.assign(oldNFeFormat, {
        model: nfe.model,
        id: nfe.id,
        serie: nfe.serie,
        number: nfe.number,
        issue_date: nfe.issue_date,
      });
      this.loadNf(oldNFeFormat, onlyData);
      return oldNFeFormat;
    },
    async getNextNfNumber() {
      const { data } = await this.$http.get(
        `/v1/nfe/documents?model=${this.value.model}&per_page=1`
      );
      let results = data.data?.results || [];

      if (results.length > 0) {
        var { serie, number } = results[0];
        number++;
      } else {
        serie = number = 1;
      }
      return { serie, number };
    },
    async sendNf(id) {
      await this.$http.post(`/v1/nfe/documents/${id}/send`);
      const nf = await this.getNf(id);

      await this.addProductTransactions({
        model: nf.model,
        serie: nf.serie,
        number: nf.number,
        operationType: nf.operation_type,
        transactionDate: nf.issue_date,
        lines: nf.lines.map(({ product_id, quantity, cfop }) => ({
          product_id,
          quantity,
          cfop,
        })),
      });
    },
    async addProductTransactions(nf) {
      let { operationType } = nf;
      if (nf.canceled) operationType += "_cancelation";

      let mathOp;
      switch (operationType) {
        case "outgoing":
          mathOp = "sub";
          break;
        case "outgoing_cancelation":
          mathOp = "add";
          break;
        case "incoming":
          mathOp = "add";
          break;
        case "incoming_cancelation":
          mathOp = "sub";
          break;
      }

      // accumulate all quantities from the same product
      let products = {};
      nf.lines.map((line) => {
        // those CFOPs doesn't change the stocks
        if (["5117", "5922"].includes(line.cfop)) return;

        if (!Object.prototype.hasOwnProperty.call(products, line.product_id)) {
          products[line.product_id] = new Decimal(0);
        }
        const quantity = products[line.product_id];
        products[line.product_id] = quantity[mathOp](line.quantity);
      });

      let treq = {
        date: nf.transactionDate,
        type: operationType,
        reference_id: `${nf.model}/${nf.serie}/${nf.number}`,
        transactions: [],
      };

      for (let [pID, amount] of Object.entries(products)) {
        treq.transactions.push({
          product_id: pID,
          amount: amount.toNumber(),
        });
      }

      return this.$http.post(`/v1/products/transactions`, treq);
    },
    async deleteNf(id, serie, number) {
      const msg = `Tem certeza que deseja deletar a Nota Fiscal ${serie} / ${number}?`;
      if (!confirm(msg)) {
        return false;
      }

      try {
        await this.$http.delete(`/v1/nfe/documents/${id}`);
        return true;
      } catch (err) {
        if (err instanceof HttpError) {
          switch (err.code) {
            case "nfe_already_sent":
              this.notifyError(
                "Esta Nota fiscal já transmitida ao <strong>SEFAZ</strong> e não<br/> pode mais ser removida"
              );
              break;
            default:
              this.$sentry.captureException(err);
              this.notifyError("Ocorreu um erro ao deletar a Nota Fiscal 😢");
          }
        } else {
          console.error(err);
          this.$sentry.captureException(err);
        }
      }
      return false;
    },
    convertNFe(nfe) {
      let sefaz_data, xml_url, danfe_url;
      if (nfe.sefaz_id) {
        sefaz_data = {
          id: nfe.sefaz_id,
          state: nfe.status,
        };
        xml_url = `https://files.giox.com.br/nfe/${nfe.sefaz_id}.xml`;
        danfe_url = `https://files.giox.com.br/nfe/${nfe.sefaz_id}.pdf`;
      }

      let reference_documents = [];
      if (nfe.references.length) {
        reference_documents = nfe.references.map((ref) => ({
          key: ref.id,
        }));
      }

      let recipient;
      if (nfe.recipient) {
        recipient = {
          name: nfe.recipient.name,
          phone: nfe.recipient.phone,
          email: nfe.recipient.email,
        };
        if (nfe.recipient.tax_id.length === 11) {
          recipient.cpf = nfe.recipient.tax_id;
        } else {
          recipient.cnpj = nfe.recipient.tax_id;
          recipient.state_tax_number = nfe.recipient.state_tax_number;
        }

        recipient.address = {
          street: nfe.recipient.address.street,
          number: nfe.recipient.address.number,
          additional: nfe.recipient.address.additional,
          neighbourhood: nfe.recipient.address.neighbourhood,
          city_id: nfe.recipient.address.city.id,
          city_name: nfe.recipient.address.city.name,
          state: nfe.recipient.address.city.state_code,
          zipcode: nfe.recipient.address.zipcode,
        };
      }

      let old = {
        id: nfe.id,
        operation: nfe.operation,

        point_of_sale: nfe.point_of_sale,
        operation_description: nfe.operation.description,
        operation_type: nfe.operation.direction,
        recipient,
        sefaz_data,
        xml_url,
        danfe_url,
        reference_documents,
        invoice: {
          amount: nfe.invoice?.total_gross,
          discount: nfe.invoice?.discount,
          receivables: [],
        },
        delivery: {},
        notes: nfe.notes,
        fiscal_notes: nfe.notes_authorities,
      };
      old.lines = nfe.lines.map((line) => ({
        product_id: line.product_id,
        description: line.description,
        unit: line.unit,
        ncm: line.ncm,
        cest: line.cest,
        goods_origin: line.origin,
        unit_price: line.unit_price,
        notes: line.notes,
        barcode: line.barcode,
        cfop: line.cfop,
        quantity: line.quantity,
        total_price: line.total_price,
        shipping_cost: line.shipping_cost,
        icms: {
          tax_code: line.icms.tax_code,
          tax_base_mode: line.icms.tax_base_mode,
          tax_base: line.icms.tax_base,
          tax_rate: line.icms.tax_rate,
          total: line.icms.total,
        },
        pis: {
          tax_code: line.pis.tax_code,
          tax_base: line.pis.tax_base,
          tax_rate: line.pis.tax_rate,
          total: line.pis.total,
        },
        cofins: {
          tax_code: line.cofins.tax_code,
          tax_base: line.cofins.tax_base,
          tax_rate: line.cofins.tax_rate,
          total: line.cofins.total,
        },
        ipi: line.ipi
          ? {
              tax_code: line.ipi.tax_code,
            }
          : null,
      }));
      old.invoice.receivables =
        nfe.invoice?.payments?.map((payment) => ({
          amount: payment.amount,
          due_date: payment.due_date,
          method: payment.method,
          done: payment.paid,
        })) || [];

      if (nfe.delivery) {
        old.delivery = {
          type: nfe.delivery.type,
        };
        if (nfe.delivery.address) {
          old.delivery.address = {
            street: nfe.delivery.address.street,
            number: nfe.delivery.address.number,
            additional: nfe.delivery.address.additional,
            neighbourhood: nfe.delivery.address.neighbourhood,
            city_id: nfe.delivery.address.city.id,
            city_name: nfe.delivery.address.city.name,
            state: nfe.delivery.address.city.state_code,
            zipcode: nfe.delivery.address.zipcode,
          };
        }
        if (nfe.delivery.packages) {
          old.delivery.packages = nfe.delivery.packages.map((pkg) => ({
            quantity: pkg.quantity,
            unit: pkg.unit,
            weight: pkg.weight,
          }));
        }
        if (nfe.delivery.courier) {
          let company = {
            name: nfe.delivery.courier.name,
          };
          if (nfe.delivery.courier.tax_id.length === 11) {
            company.cpf = nfe.delivery.courier.tax_id;
          } else {
            company.cnpj = nfe.delivery.courier.tax_id;
            switch (nfe.delivery.courier.state_tax_number) {
              case (null, undefined):
                company.state_tax_number = "";
                break;
              case "":
                company.state_tax_number = "ISENTO";
                break;
              default:
                company.state_tax_number =
                  nfe.delivery.courier.state_tax_number;
            }
          }
          if (nfe.delivery.courier.address) {
            company.street = nfe.delivery.courier.address.street;
            company.number = nfe.delivery.courier.address.number;
            company.neighbourhood = nfe.delivery.courier.address.neighbourhood;
            company.city_id = nfe.delivery.courier.address.city.id;
            company.city_name = nfe.delivery.courier.address.city.name;
            company.state = nfe.delivery.courier.address.city.state_code;
            company.zipcode = nfe.delivery.courier.address.zipcode;
          }
          old.delivery.company = company;
        }
        if (nfe.delivery.vehicle) {
          old.delivery.vehicle = {
            rntc: nfe.delivery.vehicle.rntc,
            plate: nfe.delivery.vehicle.plate,
            state: nfe.delivery.vehicle.state_code,
          };
        }
      }
      return old;
    },
    convertPaymentMethod(method) {
      switch (method) {
        case "credit_card":
          return "creditcard";
        case "debit_card":
          return "debitcard";
        case "store_credit":
          return "storecredit";
        case "gift_card":
          return "giftcard";
      }
      return method;
    },
  },
};
</script>
